<template>
  <ion-item class="ion-no-padding" lines="none">
    <ion-label v-show="showLabel" class="label" >{{ label }}<span v-if="required" class="asterisk"> *</span></ion-label>
  </ion-item>
  <ion-item class="ion-no-padding" lines="none">
    <ion-input
      :value="modelValue"
      @ionInput="$emit('update:modelValue', $event.target.value)"
      :data-cy="cyName"
      :autofocus="true"
      :type="type"
      :class="[`base-input-${variant} ${capitalizeWords ? 'capitalize-words' : ''}`]"
      :placeholder="placeholder"
      :min="min"
      :max="max"
    ></ion-input>
  </ion-item>
  <slot name="content" />

  <div v-show="loading" class="absolute right-6 top-10">...</div>

  <span v-show="error" class="errors">{{ error }}</span>
</template>

<script>
  import { IonItem, IonLabel, IonInput } from '@ionic/vue';

  export default {
    name: 'BaseInput',
    components: {
      IonItem,
      IonLabel,
      IonInput,
    },
    props: {
      cyName: { type: String, default: null },
      modelValue: {type: String, default: null},
      showLabel: { type: Boolean, default: true },
      label: { type: String, default: 'Label' },
      placeholder: { type: String },
      variant: { type: String, default: 'text' },
      min: { type: Number, default: 0 },
      max: { type: Number, default: 10000},
      required: {type: Boolean, default: false},
      type: {type: String, default: 'text'},
      error: {type: String, default: null},
      capitalizeWords: {type: Boolean, default: false}
    },
    emits: ['update:modelValue'],
  };
</script>
<style scoped lang="scss">
ion-item{
  width: 600px;
  --padding-end: 0;
  --inner-padding-end: 0;

   ion-input {
    --color: #212a4a;
    --padding-top: 21px !important;
    --padding-bottom: 21px !important;
    --padding-end: 24px !important;
    --padding-start:24px !important;
    --placeholder-font-weight: 500;
    --background: white;

    border: solid 1px #e9e9e9;
    height: 55px;
    border-radius: 250px;
    font-size: 14px;
    width: 100%;
  }
  ion-label{
    margin: 0px;
    margin-bottom: 0px !important;
    margin-left: 10px !important
  }
  .label {
    color: #212a4a;
    font-size: 17px;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    text-align: left;
  }

  .base-input-search {
    --padding-start: 60px !important;
    width: 750px;
  }
  .base-input-text {
    --padding-start: 24px !important;
    width: 600px;
  }

  .asterisk {
    color: rgb(206, 28, 28);
    font-weight: 500;
  }
}

@media screen and (max-height: 540px) {
  ion-item{
    ion-input {
      height: 45px;
      font-size: 12px;
    }
    ion-label{
      margin: 0px;
      margin-bottom: 0px !important;
      margin-left: 10px !important;
      font-size: 14px !important;
    }
  }
}


.errors {
  color: rgb(206, 28, 28);
  font-size: 12px;
  display: inline-block;
  margin-top: 4px;
  margin-left: 12px;
}

.capitalize-words {
  text-transform: capitalize;
}
</style>
